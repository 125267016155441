<!-- eslint-disable max-len -->
<template>
  <div class="flex flex-col min-h-full">
    <div class="flex-grow bg-white lg:bg-gray-100 w-full">
      <div class="max-w-lg mx-auto bg-white p-6 lg:p-12 lg:border lg:rounded-xl lg:my-6">
        <header class="text-center">
          <XCircleIcon class="p-3 h-12 w-12 mx-auto mb-3 text-red-600 bg-red-100 rounded-xl" />
          <h2 class="font-bold text-xl leading-9 mb-3">
            Limite de aplicações diárias alcançado para esse CPF
          </h2>
          <p class="text-gray-500">
            Você alcançou o limite diário de aplicações disponíveis para esse CPF. Aguarde 24 horas para aplicar novamente.
          </p>
        </header>
        <div class="mt-6">
          <div class="flex flex-col gap-5">
            <Button link @click="goToPaymentLinkMenu">
              Ir para listagem de links
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { XCircleIcon } from '@vue-hero-icons/outline';
import { Button } from '@/components';

export default {
  name: 'payment-link-number-of-link-exceeded',
  metaInfo: {
    title: 'Novo link de pagamentos',
  },
  components: {
    Button,
    XCircleIcon,
  },
  methods: {
    ...mapActions('paymentLink', ['resetPaymentLinkCreation']),
    async goToCreditCheck() {
      this.resetPaymentLinkCreation();
      await this.$router.replace({ name: 'credit-check' });
    },
    async goToPaymentLinkMenu() {
      this.resetPaymentLinkCreation();
      await this.$router.replace({ name: 'payment-link-list' });
    },
  },
};
</script>
